<template>
  <v-card>
    <v-card-title>Выберите файл для загрузки</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-file-input ref="uploader-input" @change="updateFormData"
        autofocus clearable label="File" hint="*.xls file" truncate-length="34"
      ></v-file-input>
      <div v-if="showTypes">
        <v-radio-group v-model="type" mandatory>
          <v-radio label="отчет НБУ" value="1"></v-radio>
          <v-radio label="отчет другого банка" value="2"></v-radio>
        </v-radio-group>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" :disabled="!formData || loading" :loading="loading" @click="upload">Импорт</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" :disabled="loading" @click="close">Закрыть</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'uploader',

    props: {
      storeNs: {
        required: true,
        type: String,
      },
      name: {
        type: String,
        default: 'file',
      },
      loading: Boolean,
      showTypes: Boolean,
    },

    data() {
      return {
        formData: null,
        type: 1,
      }
    },

    methods: {
      updateFormData(file) {
        if (file) {
          this.formData = new FormData()
          this.formData.append(this.name, file)
        } else {
          this.formData = null
        }
      },

      setType() {
        if (this.showTypes && this.formData) {
          this.formData.set('type', this.type)
        }
      },

      upload() {
        this.setType()

        this.$store.dispatch(this.storeNs, this.formData)
                   .then(() => {
                     this.$eventBus.$emit('notification:success', 'Импортировано')
                   })
                   .catch(() => {
                     this.$eventBus.$emit('notification:error', 'Ошибка при импорте')
                   })
                   .finally(() => {
                     this.$eventBus.$emit('upload:finished')
                     this.close()
                   })
      },

      close() {
        this.formData = null
        this.$emit('dialog:close')
        this.$refs['uploader-input'].reset()
      }
    },

    beforeDestroy() {
      this.close()
    }
  }
</script>
