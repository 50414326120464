<template>
  <v-card :loading="!!fetching">
    <v-card-title>
      {{model.name}}: {{model.balance | money}}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('dialog:close')"><v-icon color="error">mdi-close</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <div class="v-card-subtitle my-4">
        {{regionName}} <span v-if="areaName">,</span> {{areaName}}
      </div>
      <div>ИНН: {{model.tin}}</div>
      <div>ПИНФЛ: {{model.pinfl}}</div>
      <div>Телефон: <a :href="'tel:+' + model.phone">{{model.phone|phone}}</a></div>
      <div>Дата регистрации: {{model.registered_at|date}}
        <v-btn small color="primary" text @click="showChangeRegistrationForm">изменить</v-btn>

        <v-dialog v-model="showCustomerDateForm" width="500" persistent>
          <customer-date-form @customer:date-form:saved="closeCustomerDateForm"
                              @customer:date-form:cancel="closeCustomerDateForm"
          ></customer-date-form>
        </v-dialog>
      </div>

      <crms-list @crm:list:update="updateDetails" :customer-id="model.id" :items="model.crms || []"></crms-list>
      <payment-details-list :customer-id="model.id"></payment-details-list>

      <v-btn class="mt-4" v-if="!model.deleted_at" small color="error" depressed @click.ctrl="removeCustomer">Архивировать</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
  import crmsList from '@/modules/crms/list'
  import paymentDetailsList from '@/modules/payments/details'
  import customerDateForm from '@/modules/customers/date-form'

  import {createNamespacedHelpers} from 'vuex'
  const {mapState, mapActions} = createNamespacedHelpers('Customer')
  import {mapState as globalState}  from 'vuex'

  export default {
    name: 'customer-details',

    data() {
      return {
        showCustomerDateForm: false,
      }
    },

    computed: {
      ...globalState(['regions', 'areas']),
      ...mapState(['fetching', 'model']),

      regionName() {
        return (this.regions.find(r => r.id === this.model.region_id) || {}).name
      },

      areaName() {
        return (this.areas.find(r => r.id === this.model.area_id) || {}).name
      }
    },

    methods: {
      ...mapActions(['remove', 'show']),

      updateDetails() {
        this.show(this.model.id)
      },

      removeCustomer() {
        this.remove(this.model.id).then(() => $emit('dialog:close'))
      },

      showChangeRegistrationForm(item) {
        this.$store.commit('Customer/setModel', this.model)
        this.showCustomerDateForm = true
      },

      closeCustomerDateForm() {
        this.show(this.model.id)
        this.$store.commit('Customer/setModel', {})
        this.showCustomerDateForm = false
      },
    },

    components: {
      crmsList,
      paymentDetailsList,
      customerDateForm,
    },
  }
</script>
