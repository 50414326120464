<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <span style="vertical-align:middle;" class="text-h5">Платежи</span>
        <v-dialog v-model="importerDialog" width="500">
          <template #activator="{on, attrs}">
            <v-btn class="ml-3" style="vertical-align:middle;" rounded color="primary"
                   v-bind="attrs" v-on="on" small
            >
              <v-icon>mdi-upload</v-icon>
              Импорт
            </v-btn>
          </template>
          <uploader @dialog:close="closeImport" show-types
                    :loading="importing" store-ns="Payment/import"
          ></uploader>
        </v-dialog>
        <v-btn class="ml-4" @click="showEditDialog" color="success" rounded small>
          <v-icon>mdi-pen</v-icon>
          Добавить
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-menu ref="dateMenu" v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="filter.payment_dates" offset-y min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="paymentPeriod"
                          @click:clear="clearDates" clearable
                          label="Дата платежа" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.payment_dates" range no-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">Отмена</v-btn>
            <v-btn text color="primary" @click="filterByDates">Фильтровать</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-text-field v-model="filter.tin"
                      placeholder="ИНН, ПИНФЛ клиента"
                      prepend-inner-icon="mdi-account-search"
                      clearable
                      @keyup.enter="fetch(filter)"
                      @click:clear="clearTin"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
          <v-select
            v-model="filter.type"
            :items="[{id: '', title: 'все'}, {id: '-1', title: 'требуют подтверждения'}, {id: '-2', title: 'готовы подтверждению'}]"
            item-text="title"
            item-value="id"
            label="Статус"
            clearable
            @click:clear="clearType"
            @input="fetch(filter)"
          ></v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="payments"
      :loading="!!fetching"
      :sort-by="filter.sortColumns"
      :sort-desc="filter.sortOrders"
      disable-items-per-page hide-default-footer
      multi-sort
      :options.sync="options"
    >
      <template #item="{item, index}">
        <tr>
          <td class="text-center">{{10 * (filter.page - 1) + index + 1}}</td>
          <td class="text-center">{{item.payment_date|date}}</td>
          <td>{{item.doc_num}}</td>
          <td>
            <a @click="showCustomer(item.customer)">
              {{(item.customer || {tax_id: '-'}).tax_id}}
              - {{(item.customer || {name: '-'}).name}}
            </a>
          </td>
          <td>{{item.amount | money}}</td>
          <td>{{item.description}}</td>
          <td class="text-right">
            <!--            <v-btn ></v-btn><v-icon left @click="show(item)" small>mdi-eye</v-icon> show-->
            <v-btn @click="remove(item)" depressed rounded small color="error">
              <v-icon left small>mdi-close</v-icon>
              удалить
            </v-btn>
            <v-btn class="ml-1" v-if="!item.customer || !item.confirmed" @click="correntDialog(item)" depressed rounded small :color="item.unattached_customer_by_pinfl || item.unattached_customer_by_tin ? 'success' : 'warning'">
              <v-icon small>mdi-check</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination v-model="page" :total-visible="10" :length="totalPages"></v-pagination>
    </div>
    <v-dialog v-model="customerDetailDialog" width="60vw">
      <customer-details @dialog:close="customerDetailDialog = false"></customer-details>
    </v-dialog>
    <v-dialog v-model="editDialog" width="500px">
      <payment-form @dialog:close="closeEdit"></payment-form>
    </v-dialog>
    <v-dialog v-model="correctDialog" width="500">
      <validation-observer ref="observer" v-slot="{invalid, reset, handleSubmit}">
        <v-card class="payment-form-container">
          <v-card-title>Подтвердить</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-layout row wrap class="mx-4">
              <v-flex xs12>
                <table>
                  <tr><td>Сумма:</td><td>{{correction.amount|money}}</td></tr>
                  <tr><td>Детали:</td><td>{{correction.doc_num || '-'}}, {{correction.description || '-'}}</td></tr>
                  <tr v-if="correction.unattached_customer">
                    <td>Предлагаемый клиент:</td>
                    <td>
                      ИНН: {{(correction.unattached_customer || {tin: '-'}).tin}}<br>
                      ПИНФЛ: {{(correction.unattached_customer || {pinfl: '-'}).pinfl}}<br>
                      <a @click="showCustomer(correction.unattached_customer)">  Название: {{(correction.unattached_customer || {name: '-'}).name}}</a>
                    </td>
                  </tr>
                </table>
              </v-flex>
              <v-flex xs12>
                <validation-provider v-slot="{errors}" name="tin" rules="required">
                  <v-text-field v-model="correction.tax_id"
                                required :error-messages="errors"
                                label="ИНН или ПИНФЛ клиента"
                  ></v-text-field>
                </validation-provider>
              </v-flex>
              <v-flex xs12>
                <validation-provider v-slot="{errors}" name="pass" rules="required">
                  <v-text-field v-model="correction.pass"
                                required :error-messages="errors"
                                label="Пароль подтверждения"
                  ></v-text-field>
                </validation-provider>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions class="justify-center pb-3 px-3">
            <v-btn @click="saveCorrection"
                   :loading="!!correcting"
                   :disabled="invalid"
                   rounded color="success"
            >Подтвердить
            </v-btn>
            <v-btn @click="cancelCorrection"
                   :loading="!!correcting"
                   rounded text color="error"
            >Отмена</v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>

  </v-container>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import uploader from '@/components/uploader'
  import customerDetails from '@/modules/customers/details'

  import paymentForm from "./form";

  export default {
    name: 'payment-list',

    data() {
      return {
        customerDetailDialog: false,
        importerDialog: false,
        showForm: false,
        editDialog: false,
        dateMenu: false,
        filter: {
          payment_dates: [],
          page: 1,
          search: null,
          tin: null,
          sortColumns: [],
          sortOrders: [],
          type: '',
        },
        options: {
          sortBy: [],
          sortDesc: [],
        },
        correctDialog: false,
        correcting: false,
        correction: {
          tax_id: '',
          id: '',
          description: '',
          amount: '',
          doc_num: '',
          pass: '',
        }
      }
    },

    watch: {
      'options.sortBy': {
        handler (newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortBy = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
      'options.sortDesc': {
        handler (newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.filter.sortDesc = newVal
            this.filter.page = 1
            this.fetch(this.filter)
          }
        },
        deep: true,
      },
    },

    created() {
      this.fetch(this.filter)
    },

    computed: {
      ...mapState('Payment', [
        'fetching',
        'importing',
        'data',
        'model',
      ]),
      headers() {
        return [
          {text: '#', align: 'center', width: '100', sortable: false},
          {text: 'Дата', value: 'payment_date',},
          {text: 'Номер документа', sortable: false},
          {text: 'Клиент', sortable: false},
          {text: 'Сумма', sortable: false},
          {text: 'Детали', sortable: false},
          {text: 'Действия', align: 'right', sortable: false, width: '200'},
        ]
      },

      payments() {
        return this.data?.data || []
      },

      totalPages() {
        return (this.data || {last_page: 0}).last_page
      },

      page: {
        get() {
          return this.filter.page
        },

        set(val) {
          this.filter.page = val
          this.fetch(this.filter)
        }
      },

      paymentPeriod() {
        return this.filter.payment_dates.length ? this.filter.payment_dates.join(' - ') : ''
      }
    },

    methods: {
      ...mapActions('Payment', ['fetch', 'correct']),

      filterByDates() {
        this.$refs.dateMenu.save(this.filter.payment_dates)
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearTin() {
        this.filter.tin = null
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearDates() {
        this.filter.payment_dates = []
        this.filter.page = 1
        this.fetch(this.filter)
      },

      clearType() {
        this.filter.type = ''
        this.filter.page = 1
        this.fetch(this.filter)
      },

      showCustomer(customer) {
        this.customerDetailDialog = true
        this.$store.dispatch('Customer/show', customer.id)
      },

      showEditDialog() {
        this.$store.commit('Payment/setModel')
        this.editDialog = true
      },

      closeEdit() {
        this.editDialog = false
        this.fetch(this.filter)
      },

      show(payment) {

      },

      correntDialog(item) {
        this.correction = {
            ...item,
            ...{
              id: item.id,
              tax_id: (item.customer || (item.unattached_customer_by_tin || item.unattached_customer_by_pinfl) || {tax_id: ''}).tax_id,
            },
            unattached_customer: (item.customer || item.unattached_customer_by_tin || item.unattached_customer_by_pinfl),
        }
        this.correctDialog = true
      },

      saveCorrection() {
        this.correcting = true
        this.correct({
          id: this.correction.id,
          tax_id: this.correction.tax_id,
          pass: this.correction.pass
        })
            .then(() => {})
            .catch(() => {
              this.$eventBus.$emit('notification:error', 'Ошибка при подтверждении')
            })
            .finally(() => {
                this.correctDialog = false
                this.correcting = false
                this.fetch(this.filter)
            })
      },

      cancelCorrection() {
        this.correctDialog = false
      },

      remove(payment) {
        this.$store
            .dispatch('Payment/remove', payment.id)
            .then(() => this.fetch(this.filter))
      },

      closeImport() {
        this.importerDialog = false
        this.fetch(this.filter)
      }
    },

    mounted() {
      this.$eventBus.$emit('setTitle', 'Payments list')
    },

    components: {
      uploader,
      customerDetails,
      paymentForm,
    }
  }
</script>
